.card {
    display: flex;
    flex-direction: column;
    height: 100%;
    // height: 100%;
    overflow: hidden;
    color: rgb(30, 41, 59);
    box-shadow: 5px 5px 15px -1px rgba(0, 0, 0, 0.22);
    // padding: var(--padding-sm);
    background: #ffffff;
    display: grid;
    grid-template-columns: .4fr 2fr;
    min-height: calc(40vh - 20px);
    max-height: calc(50vh - 10px);
    position: relative;
    cursor: pointer;

    :hover {
        // box-shadow: 5px 5px 15px -1px rgba(0, 0, 0, 0.22);
        box-shadow: none;
      }

    @media screen and (max-width: 650px) {
        min-height: 25vh;
    }



    h3 {
        color: #9C9C9C;
        font-weight: 400;
        font-size: 14px;
        // line-height: 1.5rem;
        
        // padding: 12px;
        // padding-top: 34px;
    }

    h4 {
        // color: lightgray;
        font-weight: 400;
        font-size: 14px;
        line-height: 1rem;
        // padding: 12px;
        // padding-top: 34px;
    }


    .dash_icons{
        width: 45px;
        margin-left: 10px;
    }

    // .courses{
    //     margin-left: 12px;
    //     padding: 12px;
    // }

    .card_content_right {
       
        padding: var(--padding-sm);
    }

    .title {
        margin-top: 1.8rem;
    }

    .sub_title {
         margin-top: .4rem;
    }

    .timestamp {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
        // padding-right: 0;
        // padding-left: 0;
        // grid-auto-flow: column;
        // grid-gap: auto;
        // justify-content: space-between;
        font-size: 12px;
        // margin-top: 65px;
        position: absolute;
        bottom: 15px;
        // margin: 0;
        // margin-bottom: 0;
        // height: 40vh;
        .date {
            // display: flex;
            span{
                color: #9C9C9C;
            }
        }

        .duration {
            // display: flex;
            span{
                color: #9C9C9C;
            }
        }
    }
}

// .canvas_wrapper {
//     height: 150px;
//     margin-top: auto;
// }