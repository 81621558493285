.welcome {
    background: rgb(199, 210, 254);
    border-radius: 10px;
    padding: var(--padding-md);
    color: rgb(30, 41, 59);

    h1 {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-bottom: var(--margin-xxs);
        @media screen and (max-width: 600px) {
            font-size: 1.2rem;
        }

        svg {
            font-size: 2rem;
            flex-shrink: 0;
            @media screen and (max-width: 600px) {
                font-size: 1.5rem;
            }
        }
    }
}

.calendar {
    margin: var(--margin-md) 0;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 325px) {
        flex-direction: column;
        justify-content: flex-start;
        gap: 1rem;
        margin-top: var(--margin-md);
        margin-bottom: var(--margin-xs);
    }

    .people {
        display: flex;
        .img_container {
            width: 40px;
            height: 40px;
            overflow: hidden;
            border-radius: 50%;
            display: flex;
            border: 1px solid transparent;

            &:nth-child(2) {
                transform: translateX(-15px);
            }

            &:nth-child(3) {
                transform: translateX(-30px);
            }

            &:nth-child(4) {
                transform: translateX(-45px);
            }

            &:last-child {
                transform: translateX(-60px);
                background: white;
                display: flex;
                justify-content: center;
                align-items: center;
                border-color: lightgray;
                cursor: pointer;

                svg {
                    font-size: 1.5rem;
                    color: #6366f1;
                }
            }

            img {
                width: 100%;
                align-self: center;
            }
        }
    }

    .options {
        button {
            display: flex;
            align-items: center;
            gap: 0.3rem;
            white-space: nowrap;
            padding: var(--padding-xxs) var(--padding-sm);
            outline: none;
            border: none;
            background: #6366f1;
            color: white;
            font-weight: 500;
            font-size: 1rem;
            cursor: pointer;
            border-radius: 5px;
            transition: background 200ms ease-in-out;
            &:hover {
                background: #4648b4;
            }

            svg {
                font-size: 1.1rem;
            }
        }
    }
}

.tasks {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 650px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.lines {
    margin-top: var(--margin-md);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;

    @media screen and (max-width: 650px) {
        display: flex;
        flex-direction: column;
    }

    .lines:first-child {
        grid-column: 1/3;
        @media screen and (max-width: 930px) {
            grid-column: 1/4;
        }
    }

    .lines:nth-child(2) {
        grid-column: 3/5;
        @media screen and (max-width: 930px) {
            grid-column: 4/7;
        }
    }

    .lines:nth-child(3) {
        grid-column: 5/7;
        @media screen and (max-width: 930px) {
            grid-row: 2/3;
            grid-column: 1/4;
        }
    }

    .columns {
        grid-row: 2/3;
        grid-column: 1/4;

        @media screen and (max-width: 930px) {
            grid-column: 4/7;
        }
    }

    .pie {
        grid-row: 2/3;
        grid-column: 4/7;

        @media screen and (max-width: 930px) {
            grid-row: 3/4;
            grid-column: 1/7;
        }
    }

    .activity {
        grid-row: 3/4;
        grid-column: 1/4;
        @media screen and (max-width: 930px) {
            grid-row: 4/5;
            grid-column: 1/7;
        }
    }

    .income {
        grid-row: 3/4;
        grid-column: 4/7;
        @media screen and (max-width: 930px) {
            grid-row: 5/6;
            grid-column: 1/7;
        }
    }
}

// .activity_tabs {
//     @media screen and (max-width: 650px) {
//         display: flex;
//         flex-direction: column;
//     }
// }