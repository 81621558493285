@mixin chart_basics {
    -webkit-box-shadow: 5px 5px 15px -1px rgba(0, 0, 0, 0.22);
    box-shadow: 5px 5px 15px -1px rgba(0, 0, 0, 0.22);
    background: white;
    color: #1e293b;
    overflow: hidden;
    width: 100%;

    @media screen and (max-width : 480px) {
        height: max-content;
    }
    

    h2 {
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-size: 1rem;
        font-weight: 500;
    }

    & > * {
        padding: 0 1.2rem;
    }
}

.title {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-top: var(--margin-xs);
    margin-bottom: var(--margin-md);
    color: #1e293b;

    h1 {
        font-size: 2rem;
    }

    svg {
        font-size: 3rem;
    }
}

.container {
    position: relative;
    z-index: 1;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(6, 1fr);
}

.analytics {
    @include chart_basics();
    grid-row: 1/2;
    grid-column: 1/5;

    @media screen and (max-width: 1400px) {
        grid-column: 1/7;
    }

    .statistics {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        border-top: 1px solid rgb(228, 228, 228);

        .statistic {
            padding-left: 0.5rem;
            padding-right: 1rem;
            border-right: 1px solid rgb(238, 238, 238);
            @media screen and (max-width: 780px) {
                border: none;
            }

            &:last-child {
                border-right: none;
            }

            .overview {
                display: flex;
                align-items: center;
                gap: 1rem;

                .sum {
                    font-size: 2rem;
                    font-weight: 600;
                }

                .percentage {
                    text-align: center;
                }

                .percentage_orange {
                    color: #f59e0b;
                }

                .percetange_green {
                    color: #10b981;
                }
            }

            .statistic_title {
                font-size: 0.8rem;
                font-weight: 400;
                color: #64748b;
            }
        }
    }

    .line {
        padding-bottom: 1rem;
        height: 315px;
    }
}
