.card {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    color: rgb(30, 41, 59);
    -webkit-box-shadow: 5px 5px 15px -1px rgba(0, 0, 0, 0.22);
    box-shadow: 5px 5px 15px -1px rgba(0, 0, 0, 0.22);
    padding: var(--padding-sm);

    background: white;

    


    h3 {
        color: lightgray;
        font-weight: 400;
        font-size: 14px;
        line-height: 1rem;
        padding: 12px;
    }

    .dash_icons{
        width: 45px;
        margin-left: 10px;
    }

    .courses{
        margin-left: 12px;
    }
}

// .canvas_wrapper {
//     height: 150px;
//     margin-top: auto;
// }


