.profile_picture_container {
    display: flex;
    flex-direction: row;
    .display_profile{
        color: var(--color_nav_active);
        // max-width: ;
        // min-width: 100%;
        width: 2rem;
        // height: auto;
        // background-color: blue;
    }
}