.welcome {
    background: rgb(199, 210, 254);
    border-radius: 10px;
    padding: var(--padding-md) var(--padding-md);
    color: rgb(30, 41, 59);

    h1 {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-bottom: var(--margin-xxs);
        @media screen and (max-width: 600px) {
            font-size: 1.2rem;
        }
    }
}

.cards_layout {
    margin-top: var(--margin-md);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    

    @media screen and (max-width: 650px) {
        display: flex;
        flex-direction: column;
      
    }

}