button {
    border: none;
    outline: none;
    width: 100%;
    // padding: 15px 0;
    color: white;
    font-size: 16px;
    letter-spacing: 1px;
    background: #C52229;
    cursor: pointer;
    height: 40px;
    border-radius: 8px;

  }
  
  .login {
    width: 100%;
    min-height: 100%;
    padding: 0 20px;
    background: white;
    display: flex;
  }
  
  .login .loginContainer {
    // padding: 60px;
    padding: 32px 40px;
    margin: auto;
    width: 90%;
    max-width: 400px;
    min-height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #F8F8F8;
    // box-shadow: 0 2px 20px -1px rgba(0, 0, 0, 0.8);
    border-radius: 9px;
    border: 1px solid #D6DDE0;
    box-shadow: 0px 8px 24px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);

    span {
      color: #C52229;
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
      font-size: 12px;
      font-weight: 700;
      padding: 8px 0px 13px 0px;
    }
    
    ::placeholder {
      color: #AAAAAA;
      font-size: 12px;
    }

            br
        {   content: "A" !important;
            display: block !important;
            margin-bottom: .5rem !important;
        }
  }
  
  .login .loginContainer label {
    color: black;
    margin: 5px 0;
    display: block;
    font-size: .8rem;
    // line-height: 1;
    // font-weight: 700;
  }
  
  .login .loginContainer input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 14px;
    padding: 12px;
    background: rgba(255, 255, 255, 0.1);
    color: black;
    letter-spacing: 1px;
    // border: 1px solid #D6DDE0;
     border-radius: 8px;
    box-shadow: 0px 8px 24px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);
    // caret-color: black;
    
  }
  
  .login .loginContainer .btnContainer {
    width: 100%;
    padding: 24px 0;
  }
  
  p {
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: -5px;

  }

  .login .loginContainer .btnContainer p {
    margin: 14px 0 0 0;
    text-align: right;
    color: #fff;
    background-color: #C52229;
  }
  
  
  .login .loginContainer .errorMsg {
    color: red;
    font-size: 16px;
  }

  .form-container {
  width: 100vw;
  position: relative;
  height: 100vh;
  display: grid;
  grid-template-columns: 2fr 2fr;

  @media screen and (max-width: 620px) {
    // display: grid;
    // grid-template-columns: 1fr;

    grid-template-columns: 1fr;
    grid-template-rows: 1fr 7fr;
    row-gap: 0;
  }
}

.form-content-left {
  background: #C52229;
  position: relative;
  /* width: 100%;
  height: 100%; */
  /* top: 0;
  left: 0;
  bottom: 0; */
  /* background-size: auto; */
}

.login-photo {
  display: none;
}

.form-img {
  width: 40%;
  height: 40%;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-frame {
  width: 40%;
  position: absolute;
  left: 0;
  bottom: 0;
}


.form-content-right {
  position: relative;
  background: #F8F7FC;
}

.form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #F8F8F8;
  box-shadow: 0px 8px 24px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);
  border-radius: 9px;
  padding: 42px 40px;

  @media screen and (max-width: 1080px) {
    width: 75%;
  }

  @media screen and (max-width: 900px) {
    width: 85%;
  }

  @media screen and (max-width: 640px) {
    width: 90%;
  }

  @media screen and (max-width: 620px) {
    width: 70%;
  }

  @media screen and (max-width: 520px) {
    width: 90%;
  }
}

.login-text {
  background: #C52229;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  // font-weight: 600;
  font-size: 2.5rem;
  margin-bottom: 6px;
  font-weight: 600;
}


// .drawer{
//   background: white;
//   border: none;
// }

.form h1 {
  font-size: 1rem;
  text-align: start;
  /* display: block; */
  /* width: 80%; */
  margin-bottom: 1rem;
  color: black;
}

.form-inputs {
  margin-bottom: 0.5rem;
  width: 100%;
  background-color: #C52229;
}


// .form-inputs p {
//   font-size: 0.8rem;
//   margin-top: 0.5rem;
//   color: #f00e0e;
// }