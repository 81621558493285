// .card {
//     display: flex;
//     flex-direction: column;
//     height: 100%;
//     overflow: hidden;
//     color: rgb(30, 41, 59);
//     -webkit-box-shadow: 5px 5px 15px -1px rgba(0, 0, 0, 0.22);
//     box-shadow: 5px 5px 15px -1px rgba(0, 0, 0, 0.22);
//     padding: var(--padding-sm);

//     background: white;


//     h3 {
//         color: lightgray;
//         font-weight: 400;
//         font-size: 14px;
//         line-height: 1rem;
//         padding: 12px;
//     }

//     .dash_icons{
//         width: 45px;
//         margin-left: 10px;
//     }

//     .courses{
//         margin-left: 12px;
//     }
// }

// // .canvas_wrapper {
// //     height: 150px;
// //     margin-top: auto;
// // }


.container {
    background: white;
    padding: 1rem;
    height: 90%;
    width: 40vw;
    margin-bottom: auto;
    -webkit-box-shadow: 5px 5px 15px -1px rgba(0, 0, 0, 0.22);
    box-shadow: 5px 5px 15px -1px rgba(0, 0, 0, 0.22);  
    
    @media screen and (max-width: 650px) {
        width: 100%;
}
}

.title {
    // border-bottom: 1px solid rgb(216, 216, 216);

    h2 {
        font-weight: 500;
        font-size: 1.2rem;
        padding-top: 0.5rem;
        padding-bottom: 1rem;
        // padding-left: 0.5rem;
    }
}

.first_block {
    display: flex;
    justify-content: center;
    align-items: center;
    // top: 50%;
    // left: 50%;
    // top:0;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // display: block;
    //     margin-top: auto;
    //   margin-left: auto;
    //   margin-right: auto;
    //   margin-bottom: auto;
    //   width: 40%;

    img {
        width: 25%;
        background-size: auto;
        padding-right: 15px;
    }
}

.second_block {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    margin-top: 25px;
    // top:0;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // display: block;
//     margin-top: auto;
//   margin-left: auto;
//   margin-right: auto;
//   margin-bottom: auto;
//   width: 40%;

    img {
        width: 25%;
        background-size: auto;
        padding-right: 15px;
    }
}

p {
    color: #9C9C9C;
}

// .activity_day {
//     color: #94a3b8;
//     font-weight: 400;
//     font-size: 0.9rem;
//     padding-bottom: 0.9rem;
// }

// .activities {
//     display: flex;
//     flex-direction: column;
//     gap: 1rem;
//     margin-bottom: 2rem;
// }

// .activity {
//     display: flex;
//     align-items: center;
//     gap: 0.8rem;

//     .icon {
//         background: rgb(42, 170, 85);
//         width: 2.5rem;
//         height: 2.5rem;
//         border-radius: 50%;
//         display: flex;
//         justify-content: center;
//         align-items: center;

//         @media screen and (max-width: 550px) {
//             width: 2rem;
//             height: 2rem;
//         }

//         svg {
//             color: white;
//             font-size: 1.4rem;

//             @media screen and (max-width: 550px) {
//                 font-size: 1.2rem;
//             }
//         }
//     }

//     .content {
//         display: flex;
//         flex: 1;
//         justify-content: space-between;
//         align-items: flex-end;
//         gap: 0.5rem;
//         font-size: 1rem;
//         @media screen and (max-width: 550px) {
//             font-size: 0.8rem;
//         }

//         .view {
//             color: rgb(85, 79, 232);
//             &:hover {
//                 text-decoration: underline;
//                 cursor: pointer;
//             }

//             &:hover svg {
//                 transform: translateX(2px);
//             }

//             span {
//                 display: flex;
//                 align-items: center;
//                 gap: 0.2rem;
//             }

//             svg {
//                 transition: all ease-in-out 200ms;
//             }
//         }
//     }
// }
// .calendar {
//     display: flex;
//     justify-content: space-between;

//     @media screen and (max-width: 325px) {
//         flex-direction: column;
//         justify-content: flex-start;
//         gap: 1rem;
//         margin-top: var(--margin-md);
//         margin-bottom: var(--margin-xs);
//     }

//     .people {
//         display: flex;
//         .img_container {
//             width: 40px;
//             height: 40px;
//             overflow: hidden;
//             border-radius: 50%;
//             display: flex;
//             border: 1px solid transparent;

//             &:nth-child(2) {
//                 transform: translateX(-15px);
//             }

//             &:nth-child(3) {
//                 transform: translateX(-30px);
//             }

//             &:nth-child(4) {
//                 transform: translateX(-45px);
//             }

//             &:nth-child(5) {
//                 transform: translateX(-60px);
//             }
            
//             img {
//                 width: 100%;
//                 align-self: center;
//             }
//         }
//     }
// }

// .footer_buttons {
//     display: flex;
//     justify-content: space-between;

//     .preview_button{
//         background-color: white;
//         border: 0;
//     }

//     .next_button {
//         // background-color: yellow;
//         background-color: white;
//         border: 0;
//     }
// }